@import "bourbon";

//Open Sans
@include font-face("Open Sans", "../fonts/OpenSansLight/OpenSansLight", 300, $file-formats: woff ttf);
@include font-face("Open Sans", "../fonts/OpenSansRegular/OpenSansRegular", 400, $file-formats: woff ttf);
@include font-face("Open Sans", "../fonts/OpenSansSemibold/OpenSansSemibold", 600,  $file-formats: woff ttf);
@include font-face("Open Sans", "../fonts/OpenSansBold/OpenSansBold", 700, $file-formats: woff ttf);

//PT Sans
@include font-face("PT Sans", "../fonts/PTSansRegular/PTSansRegular", 400, $file-formats: woff ttf);
@include font-face("PT Sans", "../fonts/PTSansBold/PTSansBold", 700, $file-formats: woff ttf);